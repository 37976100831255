import React from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import { ReactComponent as Nebezpecenstvo } from '../../icons/svg/Nebezpecenstvo.svg';
import { ReactComponent as Sikana } from '../../icons/svg/Sikana.svg';
import { ReactComponent as Dav } from '../../icons/svg/Dav.svg';
import { ReactComponent as Zbran } from '../../icons/svg/Zbran.svg';
import { ReactComponent as Bitka } from '../../icons/svg/Bitka.svg';
import { ReactComponent as Krik } from '../../icons/svg/Krik.svg';
import { ReactComponent as CitimSaZle } from '../../icons/svg/CitimSaZle.svg';

interface Props {
    createIncidentHandler: (type: string) => void;
}

const IncidentButtons = (props: Props): JSX.Element => {
    const styleText = {
        color: '#F9F9F9',
        fontStyle: 'bold',
        padding: '5px',
    };

    return (
        <div className={'incident-modal'}>
            <div className={'incident-modal-buttons'}>
                <ButtonGroup orientation="vertical" aria-label="vertical outlined button group">
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('NEBEZPECENSTVO')}>
                            <Nebezpecenstvo />
                        </IconButton>
                        <span style={styleText}>Nebezpečenstvo</span>
                    </div>
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('CITIM_SA_ZLE')}>
                            <CitimSaZle />
                        </IconButton>
                        <span style={styleText}>Cítim sa zle</span>
                    </div>
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('SIKANA')}>
                            <Sikana />
                        </IconButton>
                        <span style={styleText}>Šikana</span>
                    </div>
                    <div className="incident-box">
                        <IconButton  onClick={() => props.createIncidentHandler('DAV')}>
                            <Dav />
                        </IconButton>
                        <span style={styleText}>Dav</span>
                    </div>
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('ZBRAN')}>
                            <Zbran />
                        </IconButton>
                        <span style={styleText}>Zbraň</span>
                    </div>
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('BITKA')}>
                            <Bitka />
                        </IconButton>
                        <span style={styleText}>Bitka</span>
                    </div>
                    <div className="incident-box">
                        <IconButton onClick={() => props.createIncidentHandler('KRIK')}>
                            <Krik />
                        </IconButton>
                        <span style={styleText}>Krik</span>
                    </div>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default IncidentButtons;
