import React from 'react';
import ReactDOM from 'react-dom';
import { CircularProgress } from '@mui/material';

const LoadingModal = (): JSX.Element => {
    return ReactDOM.createPortal(
        <div className="result-modal">
            <CircularProgress color="inherit" />
        </div>,
        document.body,
        // document.getElementById('modal-container')!,
    );
};

export default LoadingModal;
