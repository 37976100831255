import React, { FC, useState } from 'react';
import { AuthContextState, AuthState } from './contextTypes';

const authContextDefaultValue: AuthContextState = {
    auth: { isLogin: false, roles: [], userName: '', token: '' },
    authHandler: () => {},
};

export const AuthContext = React.createContext<AuthContextState>(authContextDefaultValue);

const AuthContextProvider: FC = ({ children }) => {
    const [auth, setAuth] = useState<AuthState>(authContextDefaultValue.auth);

    const authHandler = (auth: AuthState): void => {
        setAuth(auth);
    };

    return (
        <AuthContext.Provider
            value={{
                auth,
                authHandler,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
