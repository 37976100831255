import React from 'react';
import ReactDOM from 'react-dom';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { ResultTypeEnum } from '../../typings/types';

interface Props {
    type: ResultTypeEnum;
    text: string;
    onClose: () => void;
}

const ResultModal = (props: Props): JSX.Element => {
    return ReactDOM.createPortal(
        <div className="result-modal" onClick={props.onClose}>
            {props.type == ResultTypeEnum.ERROR ? <MdError size={'7em'} style={{ color: '#FF9180' }} /> : <MdCheckCircle size={'7em'} style={{ color: '#adf7b6' }} />}
        </div>,
        document.getElementById('modal-container')!,
    );
};

export default ResultModal;
