/* tslint:disable */
/* eslint-disable */

export interface BasicResponse {
    status?: ResponseStatusEnum;
}

export interface IncidentCreateRequest {
    type?: string;
    latitude?: string;
    longitude?: string;
}

export interface IncidentCreateResponse extends BasicResponse {
    incident?: IncidentDTO;
}

export interface IncidentDTO {
    id?: number;
    type?: IncidentTypeEnum;
    latitude?: string;
    longitude?: string;
    created?: string;
}

export interface IncidentResponse extends BasicResponse {
    incidents?: IncidentDTO[];
}

export interface TestBase {
    code?: number;
}

export interface UserResponse extends BasicResponse {
    username?: string;
    roles?: string[];
}

export enum ResponseStatusEnum {
    ERROR = 'ERROR',
    SUCCESSFUL = 'SUCCESSFUL',
    NOT_FOUND = 'NOT_FOUND',
}

export enum IncidentTypeEnum {
    UNKNOWN = 'UNKNOWN',
    NEBEZPECENSTVO = 'NEBEZPECENSTVO',
    CITIM_SA_ZLE = 'CITIM_SA_ZLE',
    SIKANA = 'SIKANA',
    DAV = 'DAV',
    ZBRAN = 'ZBRAN',
    BITKA = 'BITKA',
    KRIK = 'KRIK',
}
