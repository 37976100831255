import axios, { AxiosInstance, AxiosPromise, AxiosResponse } from 'axios';
import { BasicResponse, IncidentCreateRequest, IncidentCreateResponse, IncidentResponse } from '../typings/external';

let restClient: AxiosInstance;

const registerUnauthorizedInterceptor = () => {
    restClient.interceptors.response.use(
        (response: AxiosResponse) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                location.href = error.response.headers['location'] + encodeURI(location.href);
            }
            return Promise.reject(error);
        },
    );
};

export function initializeServices(): void {
    restClient = axios.create({ baseURL: '/mopslik/api' });
    registerUnauthorizedInterceptor();
}

export const fetchIncidents = (): AxiosPromise<IncidentResponse> => {
    return restClient.get('/public/incidents');
};

export const deleteIncident = (id: number, token: string): AxiosPromise<BasicResponse> => {
    return restClient.delete('/incident/    ' + id, { headers: { Authorization: 'Bearer ' + token } });
};

export const createIncident = (request: IncidentCreateRequest): AxiosPromise<IncidentCreateResponse> => {
    return restClient.post('/public/incident', request);
};

export const login = (username: string, password: string): AxiosPromise<BasicResponse> => {
    return restClient.post('/auth/login', null, { auth: { username: username, password: password } });
};

export const logout = (token: string): AxiosPromise<BasicResponse> => {
    return restClient.post('/auth/logout', null, { headers: { Authorization: 'Bearer ' + token } });
};
