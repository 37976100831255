import React, { FC, useState } from 'react';
import { AppContextState } from './contextTypes';
import { IncidentMarker, MyLocation } from '../typings/types';

const appContextDefaultValue: AppContextState = {
    incidents: [],
    types: [],
    filter: [],
    load: true,
    checkGPS: true,
    myLocation: { isGPSEnable: false, position: { lat: 0, lng: 0 } },
    incidentsHandler: () => {},
    filterHandler: () => {},
    typesHandler: () => {},
    loadHandler: () => {},
    checkGPSHandler: () => {},
    myLocationHandler: () => {},
};

export const AppContext = React.createContext<AppContextState>(appContextDefaultValue);

const AppContextProvider: FC = ({ children }) => {


    const [incidents, setIncidents] = useState<IncidentMarker[]>(appContextDefaultValue.incidents);
    const [filter, setFilter] = useState<string[]>(appContextDefaultValue.filter);
    const [types, setTypes] = useState<string[]>(appContextDefaultValue.types);
    const [load, setLoad] = useState<boolean>(appContextDefaultValue.load);
    const [checkGPS, setCheckGPS] = useState<boolean>(appContextDefaultValue.checkGPS);
    const [myLocation, setMyLocation] = useState(appContextDefaultValue.myLocation);

    const incidentsHandler = (incidents: IncidentMarker[]): void => {
        setIncidents(incidents);
    };

    const filterHandler = (types: string[]): void => {
        setFilter(types);
    };

    const loadHandler = (load: boolean): void => {
        setLoad(load);
    };

    const typesHandler = (types: string[]): void => {
        setTypes(types);
    };

    const checkGPSHandler = (check: boolean) => {
        setCheckGPS(check);
    };

    const myLocationHandler = (myLocation: MyLocation) => {
        setMyLocation(myLocation);
    };

    return (
        <AppContext.Provider
            value={{
                incidents,
                types,
                filter,
                load,
                checkGPS,
                myLocation,
                incidentsHandler,
                // incidentsFilterHandler,
                typesHandler,
                filterHandler,
                loadHandler,
                checkGPSHandler,
                myLocationHandler,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;
