import React, { ChangeEvent, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import ReactDOM from 'react-dom';
import { MdClose } from 'react-icons/md';
import { Divider, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../../context/authContext';
import { login } from '../../api';
import Typography from '@mui/material/Typography';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import jwt_decode from 'jwt-decode';
import { MyJwt } from '../../typings/types';

interface Props {
    onClose: () => void;
}

const LoginModal = (props: Props): JSX.Element => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { authHandler } = useContext(AuthContext);
    const [isError, setIsError] = useState(false);

    function loginHandler() {
        login(userName, password)
            .then((response) => {
                if (response.status == 200) {
                    const token = response.headers['access_token'];

                    if (token) {
                        const decodedToken = jwt_decode<MyJwt>(token);
                        authHandler({ isLogin: true, userName: decodedToken.sub!, roles: decodedToken.roles, token: token });
                    }

                    props.onClose();
                } else {
                    setIsError(true);
                }
            })
            .catch(() => setIsError(true));
    }

    function showError() {
        return (
            <>
                <Divider />
                <ErrorTwoToneIcon />
                <Typography variant="h5" gutterBottom component="div">
                    Incorrect username or password
                </Typography>
                <Divider />
            </>
        );
    }

    function userNameHandler(event: ChangeEvent<HTMLInputElement>) {
        setUserName(event.target!.value);
    }

    function handleClickShowPassword() {
        setShowPassword((prevState) => !prevState);
    }

    function passwordHandler(event: ChangeEvent<HTMLInputElement>) {
        setPassword(event.target!.value);
    }

    function modalBody(): JSX.Element {
        return (
            <section className="modal-content-section">
                {isError && showError()}
                <TextField id="outlined-start-adornment" value={userName} onChange={userNameHandler} sx={{ m: 1, width: '90%' }} size={'small'} style={{ borderRadius: '10px' }} />
                <OutlinedInput
                    sx={{ m: 1, width: '90%', borderColor: 'black' }}
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={passwordHandler}
                    size={'small'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <div className="modal-content-section-button">
                    <Button variant="contained" sx={{ color: 'black', backgroundColor: 'white', borderStyle: 'solid', borderWidth: '2px' }} disabled={userName.length == 0 || password.length == 0} onClick={loginHandler}>
                        Login
                    </Button>
                </div>
            </section>
        );
    }

    return ReactDOM.createPortal(
        <div className="modal">
            <div className="modal-content">
                <div className="modal-content-header">
                    <span className="modal-content-header-title">Login</span>
                    <MdClose className="close-button" onClick={props.onClose} />
                </div>
                {modalBody()}
            </div>
        </div>,
        document.getElementById('modal-container')!,
    );
};

export default LoginModal;
