import React, { useContext, useEffect, useState } from 'react';
import { MdOutlineGpsNotFixed, MdOutlineGpsOff } from 'react-icons/md';
import { AppContext } from '../context/appContext';

const GpsButton = (): JSX.Element => {
    const { myLocation } = useContext(AppContext);

    return <div>{myLocation.isGPSEnable ? <MdOutlineGpsNotFixed size={'1em'} className={'but-gps'} /> : <MdOutlineGpsOff size={'1em'} className={'but-gps'} />}</div>;
};

export default GpsButton;
