import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { initializeServices } from './api';
import App from './App';
import AuthContextProvider from './context/authContext';
import AppContextProvider from './context/appContext';

initializeServices();

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <AppContextProvider>
                <App />
                <div id="modal-container" />
            </AppContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
