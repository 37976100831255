import React, { useContext, useState } from 'react';
import FilterBox from './FilterBox';
import { AuthContext } from '../context/authContext';
import { ResultModalType, ResultTypeEnum } from '../typings/types';
import ResultModal from './modal/ResultModal';
import { BsDashCircleFill, BsPlusCircleFill } from 'react-icons/bs';
import { createIncident, logout } from '../api';
import { IncidentCreateRequest, ResponseStatusEnum } from '../typings/external';
import { AppContext } from '../context/appContext';
import { GrFormFilter } from 'react-icons/gr';
import LoginModal from './modal/LoginModal';
import { RiUserFollowLine, RiUserLine } from 'react-icons/ri';
import { isGeolocationEnable } from '../utils/util';
import LoadingModal from './modal/LoadingModal';
import IncidentButtons from './incident/IncidentButtons';
import GpsButton from './GpsButton';

const OperationButtons = (): JSX.Element => {
    const defaultResult: ResultModalType = { type: ResultTypeEnum.SUCCESSFUL, show: false, text: '' };

    const [showFilter, setShowFilter] = useState(false);
    const [showIncidents, setShowIncidents] = useState(false);
    const { auth, authHandler } = useContext(AuthContext);
    const { loadHandler, incidents } = useContext(AppContext);
    const [showResult, setShowResult] = useState(defaultResult);
    const [show, setShow] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    function login() {
        if (auth.isLogin) {
            logout(auth.token)
                .then((response) => {
                    if (response.data.status == ResponseStatusEnum.SUCCESSFUL) {
                        authHandler({ isLogin: false, userName: '', roles: [], token: '' });
                    }
                })
                .catch((error) => {
                    console.error('Logid/out error: ', error);
                });
        } else {
            setShow(true);
        }
    }

    function createIncidentHandler(type: string) {
        setShowIncidents(false);
        if (isGeolocationEnable()) {
            setShowResult({ show: true, type: ResultTypeEnum.ERROR, text: 'Geolocation is not allowed' });
        } else {
            setShowLoading(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const request: IncidentCreateRequest = {
                        type: type,
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString(),
                    };

                    createIncident(request)
                        .then((response) => {
                            if (response.data.status == ResponseStatusEnum.SUCCESSFUL) {
                                const incidentResponse = response.data.incident;
                                incidents.push({
                                    isNew: false,
                                    show: true,
                                    location: {
                                        id: incidentResponse!.id!,
                                        type: incidentResponse!.type!,
                                        name: incidentResponse!.type!,
                                        position: {
                                            lat: Number(incidentResponse!.latitude!),
                                            lng: Number(incidentResponse!.longitude!),
                                        },
                                    },
                                });
                                loadHandler(true);
                                setShowLoading(false);
                                setShowResult({
                                    show: true,
                                    type: ResultTypeEnum.SUCCESSFUL,
                                    text: 'Incident created!',
                                });
                            } else {
                                console.error('There is problem to create incident!');
                                setShowResult({
                                    show: true,
                                    type: ResultTypeEnum.ERROR,
                                    text: 'There is problem to create incident!',
                                });
                            }
                        })
                        .catch((error) => {
                            console.error('There is problem to create incident: ', error);
                            setShowResult({
                                show: true,
                                type: ResultTypeEnum.ERROR,
                                text: 'There is problem to create incident!',
                            });
                        });
                },
                () => {
                    console.error('There is problem to retrieve current location!');
                    setShowResult({
                        show: true,
                        type: ResultTypeEnum.ERROR,
                        text: 'There is problem to retrieve current location!',
                    });
                },
            );
        }
    }

    function showPublicCreateButton() {
        return (
            <>
                {showResult.show && <ResultModal type={showResult.type} text={showResult.text} onClose={() => setShowResult(defaultResult)} />}
                {/*{showIncidents && <IncidentModal onClose={() => setShowIncidents(false)} /> }*/}
                {showIncidents && <IncidentButtons createIncidentHandler={createIncidentHandler} />}
                {showIncidents ? <BsDashCircleFill size={'3em'} className={'but-action-create'} onClick={() => setShowIncidents((prevState) => !prevState)} /> : <BsPlusCircleFill size={'3em'} className={'but-action-create'} onClick={() => setShowIncidents((prevState) => !prevState)} />}
            </>
        );
    }

    return (
        <div>
            {auth.isLogin && <GrFormFilter size={'2em'} className={'but-action-filter'} onClick={() => setShowFilter((prevState) => !prevState)} />}
            {auth.isLogin ? <RiUserFollowLine size={'2em'} className={'but-action-login'} onClick={() => login()} /> : <RiUserLine size={'2em'} className={'but-action-login'} onClick={() => login()} />}
            {showFilter && <FilterBox />}
            {!auth.isLogin && !showLoading && showPublicCreateButton()}
            {show && <LoginModal onClose={() => setShow(false)} />}
            {showLoading && <LoadingModal />}
            <GpsButton />
        </div>
    );
};

export default OperationButtons;
