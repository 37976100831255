import question from '../icons/svg/QuestionMark-marker.svg';
import krik from '../icons/svg/Krik-marker.svg';
import sikina from '../icons/svg/Sikana-marker.svg';
import nebezpecenstvo from '../icons/svg/Nebezpecenstvo-marker.svg';
import dav from '../icons/svg/Dav-marker.svg';
import citimSaZle from '../icons/svg/CitimSaZle-marker.svg';
import bitka from '../icons/svg/Bitka-marker.svg';
import zbran from '../icons/svg/Zbran-marker.svg';

export function isGeolocationEnable(): boolean {
    return !navigator.geolocation;
}

export function getIcon(type: string) {
    let icon = question;

    if (type == 'KRIK') {
        icon = krik;
    } else if (type == 'SIKANA') {
        icon = sikina;
    } else if (type == 'NEBEZPECENSTVO') {
        icon = nebezpecenstvo;
    } else if (type == 'DAV') {
        icon = dav;
    } else if (type == 'CITIM_SA_ZLE') {
        icon = citimSaZle;
    } else if (type == 'BITKA') {
        icon = bitka;
    } else if (type == 'ZBRAN') {
        icon = zbran;
    }

    // return { url: icon, anchor: new google.maps.Point(0, 0) };
    return { url: icon };
}
