import React, { useContext, useEffect } from 'react';
import { AppContext } from '../context/appContext';
import { IncidentMarker } from '../typings/types';
import { fetchIncidents } from '../api';
import { resolveGPSPosition } from '../utils/config';
import MapPage from './MapPage';

const MainPage = (): JSX.Element => {
    const { incidentsHandler, filter, filterHandler, typesHandler, load, loadHandler, checkGPS, checkGPSHandler, myLocationHandler } = useContext(AppContext);

    useEffect(() => {
        if (load) {
            const data: IncidentMarker[] = [];
            const types: string[] = [];
            const actualFilter: string[] = filter;
            fetchIncidents()
                .then((response) => {
                    response.data.incidents?.map((i) => {
                        if (!types.some((t) => t == i.type)) {
                            types.push(i.type!);
                            actualFilter.push(i.type!);
                        }

                        const incidentMarker: IncidentMarker = {
                            isNew: false,
                            show: actualFilter.length == 0 ? true : actualFilter.some((t) => t == i.type!),
                            location: {
                                id: i.id!,
                                type: i.type!,
                                name: i.type!,
                                position: { lat: parseFloat(i.latitude!), lng: parseFloat(i.longitude!) },
                            },
                        };

                        data.push(incidentMarker);
                    });

                    if (checkGPS) {
                        resolveGPSPosition().then((p) => {
                            checkGPSHandler(false);
                            myLocationHandler({
                                isGPSEnable: true,
                                position: { lat: p.coords.latitude, lng: p.coords.longitude },
                            });
                        });
                    }

                    incidentsHandler(data);
                    typesHandler(types);
                    filterHandler(actualFilter);
                })
                .finally(() => {
                    loadHandler(false);
                });
        }
    }, [load, checkGPS]);

    return (
        <div className={'main-page-map-box'}>
            <MapPage />
        </div>
    );
};

export default MainPage;
