import React, { SyntheticEvent, useState } from 'react';
import Button from '@mui/material/Button';
import { IncidentMarker } from '../../typings/types';
import { IncidentTypeEnum } from '../../typings/external';

interface Props {
    incident: IncidentMarker;
    onCreate: (type: string) => void;
}

const IncidentCreate = (props: Props): JSX.Element => {
    const [type, setType] = useState<string>('default');

    function onChange(event: SyntheticEvent<HTMLSelectElement, Event>): void {
        setType(event.currentTarget.value);
    }
    return (
        <div className="inc-create">
            <div className="inc-create-info">
                <span className="inc-create-info-label">Incident type:</span>
                <div className="inc-create-info-select-box">
                    <select onChange={onChange}>
                        <option key={0} value={'default'}>
                            {''}
                        </option>
                        {Object.keys(IncidentTypeEnum)
                            .filter((i) => i.toString() != 'UNKNOWN')
                            .map((it) => (
                                <option key={it} value={it}>
                                    {it}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className="inc-create-button">
                <Button size="small" onClick={() => props.onCreate(type)} disabled={type == 'default'}>
                    Create incident
                </Button>
            </div>
        </div>
    );
};

export default IncidentCreate;
