import React, { useContext } from 'react';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import { AppContext } from '../context/appContext';

const FilterBox = (): JSX.Element => {
    const { types, filter, filterHandler, incidents, incidentsHandler } = useContext(AppContext);

    const switchStyle = {
        color: 'black',
    };

    function updateBaseFilter(type: string, show: boolean) {
        const newIncidents = incidents.map((item) => {
            if (item.location.type == type) {
                return { ...item, show: show };
            }
            return item;
        });
        incidentsHandler(newIncidents);
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        const type = e.target.value;
        if (checked) {
            filterHandler([...filter, type]);
        } else {
            filterHandler(filter.filter((t) => t != type));
        }
        updateBaseFilter(type, checked);
    }

    function isChecked(value: string): boolean {
        return filter.some((i) => i == value);
    }

    function isDisabled(value: string): boolean {
        return !types.some((i) => i == value);
    }

    return (
        <div className="inc-card">
            <FormGroup className="inc-card-form-group">
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('NEBEZPECENSTVO')} disabled={isDisabled('NEBEZPECENSTVO')} size={'small'} value={'NEBEZPECENSTVO'} onChange={onChange} />} label="Nebezpečenstvo" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('CITIM_SA_ZLE')} disabled={isDisabled('CITIM_SA_ZLE')} size={'small'} value={'CITIM_SA_ZLE'} onChange={onChange} />} label="Cítim sa zle" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('SIKANA')} disabled={isDisabled('SIKANA')} size={'small'} value={'SIKANA'} onChange={onChange} />} label="Šikana" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('DAV')} disabled={isDisabled('DAV')} size={'small'} value={'DAV'} onChange={onChange} />} label="Dav" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('ZBRAN')} disabled={isDisabled('ZBRAN')} size={'small'} value={'ZBRAN'} onChange={onChange} />} label="Zbraň" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('BITKA')} disabled={isDisabled('BITKA')} size={'small'} value={'BITKA'} onChange={onChange} />} label="Bitka" />
                <FormControlLabel control={<Switch style={switchStyle} color="default" checked={isChecked('KRIK')} disabled={isDisabled('KRIK')} size={'small'} value={'KRIK'} onChange={onChange} />} label="Krik" />
            </FormGroup>
        </div>
    );
};

export default FilterBox;
