import Button from '@mui/material/Button';
import React from 'react';
import InfoBox from '../common/InfoBox';

interface Props {
    id: number;
    name: string;
    onDelete: () => void;
}

const IncidentDetail = (props: Props): JSX.Element => {
    return (
        <div className="inc-detail">
            <div className="inc-detail-info">
                <InfoBox name={'ID'} value={props.id.toString()} />
                <InfoBox name={'Type'} value={props.name} />
            </div>
            <div className={'inc-detail-button'}>
                <Button size="small" onClick={props.onDelete}>
                    Delete incident
                </Button>
            </div>
        </div>
    );
};

export default IncidentDetail;
