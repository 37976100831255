import React from 'react';

interface Props {
    name: string;
    value: string;
}

const InfoBox = (props: Props): JSX.Element => {
    return (
        <div className="info-box">
            <label className="info-box-label">{props.name}</label>
            <span className="info-box-value">{props.value}</span>
        </div>
    );
};

export default InfoBox;
