import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { defaultResult, ResultModalType } from '../typings/types';
import { AppContext } from '../context/appContext';
import Incident from '../components/incident/Incident';
import ButtonsOperation from '../components/OperationButtons';
import ResultModal from '../components/modal/ResultModal';
import { AuthContext } from '../context/authContext';
import { defaultCenter, mapOptions, mapStyle } from '../utils/map';

const MapPage = (): JSX.Element => {
    const apiKey: string = process.env.REACT_APP_GOOGLE_API_KEY ?? '';

    const [mapRef, setMapRef] = useState<google.maps.Map>();
    const { incidents, incidentsHandler, myLocation } = useContext(AppContext);
    const { auth } = useContext(AuthContext);
    const [result, setResultState] = useState<ResultModalType>(defaultResult);

    useEffect(() => {
        // console.log('MapPage useEffect');
    }, [incidents]);

    function onClick(e: google.maps.MapMouseEvent) {
        if (auth.isLogin) {
            const newMarker = e.latLng?.toJSON();

            incidentsHandler([
                ...incidents,
                {
                    isNew: true,
                    show: true,
                    location: {
                        id: Math.random() * 100,
                        position: { lat: newMarker!.lat, lng: newMarker!.lng },
                        type: 'NEW',
                        name: 'NEW',
                    },
                },
            ]);
        }
    }

    return (
        <LoadScript googleMapsApiKey={apiKey}>
            <GoogleMap
                zoom={14}
                onLoad={(map) => {
                    setMapRef(map);
                }}
                center={myLocation.isGPSEnable ? myLocation.position : defaultCenter}
                mapContainerStyle={mapStyle}
                options={mapOptions}
                onClick={onClick}
            >
                <div>
                    <ButtonsOperation />
                    {incidents
                        .filter((i) => i.show)
                        .map((m, i) => {
                            return <Incident key={i} incident={m} />;
                        })}
                    <Marker position={myLocation.position} key={0} />
                </div>
            </GoogleMap>
            {result.show && <ResultModal type={result.type} text={result.text} onClose={() => setResultState(defaultResult)} />}
        </LoadScript>
    );
};

export default MapPage;
