import { JwtPayload } from 'jwt-decode';

export interface IncidentContext {
    incidents?: IncidentMarker[];
    changeIncidents?: () => void;
}

export interface IncidentMarker {
    isNew: boolean;
    show: boolean;
    location: Location;
}

export interface Location {
    id: number;
    name: string;
    type: string;
    position: Position;
}

export interface Position {
    lat: number;
    lng: number;
}

export interface MyJwt extends JwtPayload {
    roles: string[];
}

export interface ResultModalType {
    type: ResultTypeEnum;
    text: string;
    show: boolean;
}

export enum ResultTypeEnum {
    ERROR = 'ERROR',
    SUCCESSFUL = 'SUCCESSFUL',
}

export const defaultResult: ResultModalType = {
    type: ResultTypeEnum.SUCCESSFUL,
    show: false,
    text: '',
};

export interface MyLocation {
    isGPSEnable: boolean;
    position: Position;
}
