import React, { useContext } from 'react';
import MainPage from './pages/MainPage';
import LoadingModal from './components/modal/LoadingModal';
import { AppContext } from './context/appContext';

function App() {
    const { load } = useContext(AppContext);

    return (
        <div className={'main-page'}>
            <MainPage />
            {load && <LoadingModal />}
        </div>
    );
}

export default App;
