import React, { useContext, useState } from 'react';
import { defaultResult, IncidentMarker, ResultModalType, ResultTypeEnum } from '../../typings/types';
import { InfoWindow, Marker } from '@react-google-maps/api';
import IncidentCreate from './IncidentCreate';
import IncidentDetail from './IncidentDetail';
import { createIncident, deleteIncident } from '../../api';
import { AppContext } from '../../context/appContext';
import { IncidentCreateRequest, ResponseStatusEnum } from '../../typings/external';
import { AuthContext } from '../../context/authContext';
import ResultModal from '../modal/ResultModal';
import { getIcon } from '../../utils/util';

interface Props {
    incident: IncidentMarker;
    onClickHandler?: (id: number) => void;
}

const Incident = (props: Props): JSX.Element => {
    const [showInfo, setShowInfo] = useState(props.incident.isNew);
    const { incidents, incidentsHandler } = useContext(AppContext);
    const { auth } = useContext(AuthContext);
    const [result, setResultState] = useState<ResultModalType>(defaultResult);

    function onDeleteHandler() {
        let newIncidents = [...incidents];
        let newResult = defaultResult;

        deleteIncident(props.incident.location.id, auth.token)
            .then((response) => {
                if (response.data.status == ResponseStatusEnum.SUCCESSFUL) {
                    newIncidents = newIncidents.filter((i) => i.location.id != props.incident.location.id);
                    newResult = { show: true, type: ResultTypeEnum.SUCCESSFUL, text: 'Incident deleted!' };
                } else {
                    newResult = { show: true, type: ResultTypeEnum.ERROR, text: 'Error!' };
                }
            })
            .catch((error) => {
                console.error('Error delete incident: ', error);
                newResult = { show: true, type: ResultTypeEnum.ERROR, text: 'Error!' };
            })
            .finally(() => {
                if (newResult.type == ResultTypeEnum.SUCCESSFUL) {
                    incidentsHandler(newIncidents);
                }
                setShowInfo(false);
                setResultState(newResult);
            });
    }

    function createIncidentHandler(type: string) {
        const lat = props.incident.location.position.lat.toString();
        const long = props.incident.location.position.lng.toString();
        const newIncidents = incidents.filter((i) => i.location.id != props.incident.location.id);

        const request: IncidentCreateRequest = {
            type: type,
            latitude: lat,
            longitude: long,
        };

        createIncident(request)
            .then((response) => {
                if (response.data.status == ResponseStatusEnum.SUCCESSFUL) {
                    const incidentResponse = response.data.incident;
                    newIncidents.push({
                        isNew: false,
                        show: true,
                        location: {
                            id: incidentResponse!.id!,
                            type: incidentResponse!.type!,
                            name: incidentResponse!.type!,
                            position: {
                                lat: Number(incidentResponse!.latitude!),
                                lng: Number(incidentResponse!.longitude!),
                            },
                        },
                    });
                    incidentsHandler(newIncidents);
                    setResultState({ show: true, type: ResultTypeEnum.SUCCESSFUL, text: 'Incident created!' });
                }
            })
            .catch((error) => {
                console.error('ERROR createIncident: ' + error);
                setResultState({ show: true, type: ResultTypeEnum.ERROR, text: 'Error!' });
            })
            .finally(() => setShowInfo(false));
    }

    function onShowInfo() {
        if (auth.isLogin) {
            setShowInfo(true);
        }
    }

    return (
        <>
            <Marker position={props.incident.location.position} key={props.incident.location.id} onClick={onShowInfo} icon={getIcon(props.incident.location.type)} />
            {showInfo && (
                <InfoWindow
                    position={props.incident.location.position}
                    onCloseClick={() => {
                        setShowInfo(false);
                    }}
                >
                    {props.incident.isNew ? <IncidentCreate incident={props.incident} onCreate={createIncidentHandler} /> : <IncidentDetail id={props.incident.location.id} name={props.incident.location.name} onDelete={onDeleteHandler} />}
                </InfoWindow>
            )}
            {result.show && <ResultModal type={result.type} text={result.text} onClose={() => setResultState(defaultResult)} />}
        </>
    );
};

export default Incident;
